define("discourse/plugins/discourse-dictionary/discourse/controllers/select-meaning-popup", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/array", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _controller, _modalFunctionality, _computed, _ajax, _ajaxError, _array, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    loading: false,
    errorMessage: null,
    word: (0, _computed.alias)("model.word"),
    meanings: (0, _array.A)(),
    insertDisabled(selectedDefinition) {
      return !selectedDefinition;
    },
    getMeanings(word) {
      return (0, _ajax.ajax)("/discourse-dictionary/word", {
        data: {
          word
        }
      }).then(response => {
        return response?.word_definitions?.definitions;
      }).catch(_ajaxError.popupAjaxError);
    },
    onShow() {
      this.set("isLoading", true);
      this.getMeanings(this.word).then(data => {
        this.set("meanings", data);
      }).finally(() => this.set("isLoading", false));
    },
    changeDefinition(definition) {
      this.set("selectedDefinition", definition);
      let selectedMeaning = this.meanings.find(meaning => {
        return meaning.definition === definition;
      });
      this.set("selectedMeaning", selectedMeaning);
    },
    insertMeaning() {
      let meaning = this.get("selectedMeaning");
      let definition = meaning.definition;
      let lexical_category = meaning.lexical_category;
      this.toolbarEvent.applySurround(`[dict meaning="${definition}" lexical="${lexical_category}"]`, "[/dict]", "dictionary_meaning");
      this.send("closeModal");
    }
  }, [["method", "insertDisabled", [(0, _decorators.default)("selectedDefinition")]], ["method", "changeDefinition", [_object.action]], ["method", "insertMeaning", [_object.action]]]));
});